.container {
    display: flex;
    overflow: auto;
    flex: none;
    width: 100%;
    height: calc(100vh - 3rem);
    flex-flow: column nowrap;
    scroll-behavior: smooth;
}

.container>div:first-child {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.container>div {
    text-align: center;
    scroll-snap-align: center;
    flex: none;
    justify-items: center;
    width: 100%;
    height: 100%;
}

/* .container>div:nth-child(even) {
    background-color: #87ea87;
} */

.scrollSnap {
    scroll-snap-type: y mandatory;
}

.smartbidContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: flex-start;
    gap: var(--gap-md);
    width: 100%;
    max-width: 25.5rem;
    height: 85%;
}

.widerContent {
    max-width: 35rem;
}

.homeSection {
    position: relative;
}

@media (max-height: 808px) {
    .scrollSnap {
        scroll-snap-type: none;
    }
}

/*
███████  ██████ ██████   ██████  ██      ██           
██      ██      ██   ██ ██    ██ ██      ██           
███████ ██      ██████  ██    ██ ██      ██           
     ██ ██      ██   ██ ██    ██ ██      ██           
███████  ██████ ██   ██  ██████  ███████ ███████      
                                                      
                                                      
██████  ██    ██ ████████ ████████  ██████  ███    ██ 
██   ██ ██    ██    ██       ██    ██    ██ ████   ██ 
██████  ██    ██    ██       ██    ██    ██ ██ ██  ██ 
██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██ 
██████   ██████     ██       ██     ██████  ██   ████ 
*/


.scrollButton {
    position: absolute;
    left: 50vw;
    bottom: 5vh;
}

.scrollButton a {
    max-width: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    font-weight: 900;
    font-size: var(--text-xs);
    color: var(--grey-700);
}

.scrollButton a:hover {
    color: var(--grey-800);
}

.scrollButton svg {
    font-size: var(--text-2xl);
    animation: scrollbutton-ambient 2s infinite;
    -webkit-animation: scrollbutton-ambient 2s infinite;
}

.scrollButton a:hover svg {
    animation: scrollbutton-hover 0.5s forwards;
    -webkit-animation: scrollbutton-hover 0.5s forwards;
}

@-webkit-keyframes scrollbutton-ambient {
    0% {
        -webkit-transform: translate(0, 0);
    }

    20% {
        -webkit-transform: translate(0, 10px);
    }

    40% {
        -webkit-transform: translate(0, 0);
    }
}

@keyframes scrollbutton-ambient {
    0% {
        transform: translate(0, 0);
    }

    20% {
        transform: translate(0, 10px);
    }

    40% {
        transform: translate(0, 0);
    }
}

@-webkit-keyframes scrollbutton-hover {
    0% {
        -webkit-transform: translate(0, 0);
    }

    100% {
        -webkit-transform: translate(0, 10px);
    }
}

@keyframes scrollbutton-hover {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(0, 10px);
    }
}

/*
███████ ███    ███  █████  ██████  ████████ ██████  ██ ██████  
██      ████  ████ ██   ██ ██   ██    ██    ██   ██ ██ ██   ██ 
███████ ██ ████ ██ ███████ ██████     ██    ██████  ██ ██   ██ 
     ██ ██  ██  ██ ██   ██ ██   ██    ██    ██   ██ ██ ██   ██ 
███████ ██      ██ ██   ██ ██   ██    ██    ██████  ██ ██████  
                                                               
                                                               
 ██████  █████  ██████  ██████                                 
██      ██   ██ ██   ██ ██   ██                                
██      ███████ ██████  ██   ██                                
██      ██   ██ ██   ██ ██   ██                                
 ██████ ██   ██ ██   ██ ██████                                 
*/

.smartbidForm {
    display: flex;
    align-items: flex-start;
    gap: var(--gap-half);
}

.formContent,
.droppable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-three-quarters);
    width: 100%;
}

.row {
    display: flex;
    gap: var(--gap-quarter);
    width: 100%;
}

.deleteStopWrapper button {
    margin-top: 0.1875rem;
    margin-left: 0.25rem;
}

#lastRow {
    display: flex;
    flex-direction: row;
    gap: var(--gap-half);
    width: 100%;
}

.reverseButton {
    display: flex;
    margin-top: 2rem;
    padding: 0.5rem;
    border: none;
    outline: none;
    border-radius: var(--radius-round);
    font-size: var(--text-2xl);
    cursor: pointer;
    background-color: var(--secondary-dark);
}

.reverseButton:hover {
    background: var(--secondary-darker);
}

.dragButton {
    display: flex;
    height: min-content;
    margin-top: 0.625rem;
    margin-left: -0.5rem;
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    font-size: var(--text-2xl);
    color: var(--secondary-darkest);
}

.dragButton:hover {
    cursor: grab;
}

.dragButton:active {
    cursor: grabbing;
}

.draggingRow {
    opacity: 0.5;
}

#lastRow.stopSpacing {
    padding-left: 1.25rem;
    padding-right: 3rem;
}

/*
███████ ███    ███  █████  ██████  ████████ ██████  ██ ██████  
██      ████  ████ ██   ██ ██   ██    ██    ██   ██ ██ ██   ██ 
███████ ██ ████ ██ ███████ ██████     ██    ██████  ██ ██   ██ 
     ██ ██  ██  ██ ██   ██ ██   ██    ██    ██   ██ ██ ██   ██ 
███████ ██      ██ ██   ██ ██   ██    ██    ██████  ██ ██████  
                                                               
                                                               
██████  ███████ ███████ ██    ██ ██      ████████ ███████      
██   ██ ██      ██      ██    ██ ██         ██    ██           
██████  █████   ███████ ██    ██ ██         ██    ███████      
██   ██ ██           ██ ██    ██ ██         ██         ██      
██   ██ ███████ ███████  ██████  ███████    ██    ███████      
*/

.results {
    display: flex;
    justify-content: center;
    gap: var(--gap);
    width: 100%;
    height: 100%;
    padding: 16px;
    box-shadow: inset 0 5px 15px -2px rgb(0, 0, 0, 0.25);
    border-radius: var(--radius-lg);
    overflow: auto;
}

.bids {
    color: var(--grey-500);
    font-size: var(--text-sm);
    font-weight: 600;
    text-align: left;
}

.bids span {
    color: var(--primary-500);
    display: inline;
}

.smartbidResultsWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    width: 100%;
}

.smartbidResultsWrapper p {
    margin: 0;
    font-size: var(--text-base);
}

.threeColumns,
.threeColumns .smartbidResultsWrapper {
    max-width: 36rem;
}

.skeletonResults {
    width: 100%;
}

.line {
    height: 5px;
    width: 100%;
}

.line line {
    stroke: var(--primary-800);
    stroke-width: 2px;
}

.dottedLine {
    height: 5px;
    width: 100%;
}

.dottedLine line {
    stroke: var(--grey-400);
    stroke-width: 2px;
}

.vehicleTitle {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: var(--gap-half);
    align-items: center;
}

.vehicleTitle h1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-half);
    font-family: var(--font-sans);
    font-size: var(--text-small);
    color: var(--primary-800);
    font-weight: 700;
    white-space: nowrap;
    margin: 5px 0;
}

.vehicleTitle svg:first-child {
    font-size: var(--text-base);
}

.vehicleHeader {
    display: flex;
    flex-direction: column;
    gap: var(--gap-half);
    align-items: center;
}

.narrowVehicleHeader {
    display: flex;
    flex-direction: column;
    gap: var(--gap-quarter);
    align-items: center;
    margin-bottom: var(--gap-half);
}

.subclassHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: var(--gap-half);
    align-items: center;
}

.subclassHeader h2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-half);
    font-family: var(--font-sans);
    font-size: var(--text-sm);
    color: var(--grey-600);
    font-weight: 700;
    white-space: nowrap;
    margin: 5px 0;
}

.subclassHeader svg:first-child {
    font-size: var(--text-sm);
}

.vehicleRates {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.vehicleRates h2 {
    text-align: center;
    font-family: var(--font-sans);
    font-size: var(--text-sm);
    font-weight: 700;
    margin: 5px 0;
}

.actualRates {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: var(--gap-quarter);
}

.totalAmt {
    font-size: var(--text-lg);
    font-weight: 700;
}

.market .totalAmt {
    font-weight: 600;
}

.amtPerMile {
    font-size: var(--text-sm);
}

.driverRate {
    padding-top: 0.25rem;
    white-space: nowrap;
    font-size: var(--text-sm);
}

.historical,
.market,
.smartbid {
    width: 100%;
    text-align: center;
}

.historical span {
    color: var(--secondary-darkest);
}

.historical h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 1.875rem;
    background-color: var(--secondary-dark);
    color: var(--secondary-darkest);
    padding: 0.375rem 0.5rem;
    font-size: var(--text-sm) !important;
}

.historical .driverRate {
    color: var(--secondary-darkest);
}

.market span {
    color: var(--grey-600);
}

.market h2 {
    background-color: var(--grey-200);
    color: var(--grey-600);
    padding: 0.375rem 0;
}

.market .driverRate {
    color: var(--grey-600);
}

.smartbid span {
    color: var(--primary-500);
}

.smartbid h2 {
    background-color: var(--primary-500);
    color: var(--secondary);
    padding: 0.375rem 0;
}

.smartbid .driverRate {
    color: var(--primary-500);
}

/*
██       ██████   ██████  █████  ████████ ██  ██████  ███    ██ 
██      ██    ██ ██      ██   ██    ██    ██ ██    ██ ████   ██ 
██      ██    ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██ 
██      ██    ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██ 
███████  ██████   ██████ ██   ██    ██    ██  ██████  ██   ████ 

██████  ███████ ███████ ██    ██ ██      ████████ ███████ 
██   ██ ██      ██      ██    ██ ██         ██    ██      
██████  █████   ███████ ██    ██ ██         ██    ███████ 
██   ██ ██           ██ ██    ██ ██         ██         ██ 
██   ██ ███████ ███████  ██████  ███████    ██    ███████ 
*/

.routeInfo {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-half);
    color: var(--blue-500);
    font-weight: 700;
    font-size: var(--text-base);
    white-space: nowrap;
}

.routeInfo svg {
    color: var(--blue-200);
}

.locationResults {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-half);
    justify-content: space-between;
    align-items: center;
    margin-top: 0.7rem;
}

.locationRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--brown-50);
    border-radius: var(--radius-round);
    padding: 5px 10px;
}

.locLabel {
    font-size: var(--text-base);
    color: var(--grey-600);
    font-weight: 700;
}

.infoCircle {
    font-size: var(--text-base);
    color: var(--secondary-darkest);
}

.infoCircle:hover {
    color: var(--primary-800);
    cursor: pointer;
}

.locValue {
    display: flex;
    align-items: flex-start;
    gap: var(--gap-quarter);
    white-space: nowrap;
    font-size: var(--text-md);
    font-weight: 700;
    color: var(--primary-500);
}

#externalLink {
    color: var(--primary-500);
}

.tooltip {
    max-width: 15rem;
    font-weight: 400;
}

.historicalTooltip {
    max-width: 30rem;
    opacity: .7 !important;
}

.distanceAmt {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: var(--gap-half);
    white-space: nowrap;
    color: var(--blue-500);
}

.distanceAmt svg {
    color: var(--blue-200);
}

@media (max-width: 480px) {
    #lastRow {
        flex-direction: column;
    }

    .reverseButton {
        background-color: var(--grey-200);
    }

    .reverseButton:hover {
        background: var(--grey-300);
    }
}

@media (min-width: 350px) {
    .vehicleRates {
        flex-direction: row;
    }
}

@media (min-width: 425px) {
    .vehicleHeader {
        flex-direction: row;
    }
}

@media (min-width: 480px) {

    .locLabel,
    .locValue {
        font-size: var(--text-md);
    }

    .vehicleTitle h1 {
        font-size: var(--text-base);
    }

    .vehicleRates h2 {
        font-size: var(--text-base);
    }

    .totalAmt {
        font-size: var(--text-xl);
    }

    .amtPerMile,
    .driverRate {
        font-size: var(--text-base);
    }
}

@media (min-width: 1024px) {
    .smartbidContent {
        align-items: flex-start;
        align-self: flex-start;
    }
}

/*
██        █████  ███    ██ ███████
██       ██   ██ ████   ██ ██
██       ███████ ██ ██  ██ █████
██       ██   ██ ██  ██ ██ ██
███████  ██   ██ ██   ████ ███████

██████  ███████ ████████  █████  ██ ██      ███████
██   ██ ██         ██    ██   ██ ██ ██      ██     
██   ██ █████      ██    ███████ ██ ██      ███████
██   ██ ██         ██    ██   ██ ██ ██           ██
██████  ███████    ██    ██   ██ ██ ███████ ███████
*/

.laneDetails {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
    padding: 16px;
    height: 80vh;
}

.laneDetailTable {
    grid-column: 1;
    grid-row: span 2;
}

.topCustomers {
    grid-column: 2;
    grid-row: 2;
}

/* CHART CARD STYLES */
.chartCard {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap-half);
    padding: 0.5rem;
    padding-bottom: 1rem;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    background-color: var(--secondary);
    width: 100%;
    grid-column: 2;
    grid-row: 1;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap);
    white-space: nowrap;
}

.secondHeader {
    display: flex;
    align-items: center;
}

.secondHeader p {
    align-self: flex-start;
    margin-top: 0px;
    color: var(--grey-700);
}

.heading {
    font-size: var(--text-2xl);
    margin: 0;
    color: var(--grey-800);
}

.actionsWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.optionsWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-quarter);
}

@media (min-width: 420px) {
    .heading {
        font-size: 1.75rem;
    }
}

@media (min-width: 896px) {
    .optionsWrapper {
        flex-direction: row;
    }

    .actionsWrapper {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1200px) {
    .header {
        flex-direction: row;
        align-items: center;
    }
}