.vehicleManager {
  width: 100%;
  max-height: calc(100vh - 6rem);
  box-shadow: var(--shadow);
  overflow-x: auto;
}

.vehicleManager::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

.vehicleManager::-webkit-scrollbar-track {
  padding: 1px 0;
  border: 2px solid var(--secondary);
}

.vehicleManager::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  background-clip: padding-box;
  background-color: var(--secondary-darker);
  border-radius: var(--radius);
}

.vehicleManager::-webkit-scrollbar-thumb:hover {
  border: 2px solid transparent;
  background-color: var(--secondary-darkest);
}

.vehicleNotes {
  text-overflow: ellipsis;
  /* word-wrap: break-word; */
}

.manageDriverAppWrapper {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr 2rem 1fr;
  row-gap: .875rem;
  column-gap: .875rem;
}

.scrollSnap {
  scroll-snap-type: y mandatory;
}

.driverDetailTable {
  grid-column: span 2;
  grid-row: 1;
  min-height: 250px;
}

.opportunityListTable {
  grid-column: 2;
  grid-row: 3;
}

.loading {
  height: 100%;
}

.driverSelector {
  grid-row: 2;
  grid-column: 2;
  align-self: end;
  justify-self: end;
}

.rateSelector {
  grid-row: 3;
  grid-column: 1;
}

/* .vehicleID {
  color: var(--grey-900);
} */

.vehicleStatus {
  /* background-color: aqua; */
  padding: 5px 10px;
  border-radius: var(--radius-round);
  width: fit-content;
  text-wrap: nowrap;
  font-weight: 500;
}

.vehicleStatusGreen {
  color: var(--everest-white);
  background-color: var(--green-600);
}

.vehicleStatusOrange {
  color: var(--brown-700);
  background-color: #F4C518;
}

.vehicleStatusRed {
  color: var(--everest-white);
  background-color: var(--everest-red);
}

@media (max-height: 808px) {
  .scrollSnap {
    scroll-snap-type: none;
  }
}

.vehicleManagerTableWrapper table {
  position: relative;
  width: 100%;
}

.vehicleManagerTableWrapper table caption,
.vehicleManagerTableWrapper table thead {
  z-index: 102;
  position: sticky;
  top: 0;
}

.vehicleManagerTableWrapper table caption {
  background-color: var(--secondary);
}

.vehicleManagerTableWrapper table thead {
  z-index: 101;
  top: 127px;
  box-shadow: var(--shadow);
}

.stickyColumn {
  position: sticky;
  left: 0;
  box-shadow: var(--shadow);
}

.stickyColumn.editableCellColumnHeader {
  z-index: 102;
  background: var(--secondary);
}

.vehicleManagerTableWrapper tr .stickyColumn.editableCell {
  z-index: 2;
  background: var(--everest-white);
}

.vehicleManagerTableWrapper tr:nth-child(2n) .stickyColumn.editableCell {
  background: var(--secondary);
}

.vehicleManagerTableCaption {
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary);
  width: max-content;
  max-width: 440px;
  margin-right: auto;
  font-weight: 400;
  padding: 10px 10px 0;
  padding-bottom: 6px;
  text-align: left;
  display: flex;
  gap: var(--gap-half);
  align-content: baseline;
  color: var(--primary-800);
}

.vehicleManagerTableCaption .title {
  font-family: var(--font-head);
  font-size: 1.75rem;
}

.vehicleManagerTableCaption .subtitle {
  padding-bottom: 6px;
}

.vehicleManagerTableActions {
  display: flex;
  gap: var(--gap-half);
}

.vehicleManagerTableCaption button {
  font-size: 1rem;
  font-family: var(--font-sans);
}

.vehicleManagerTableWrapper td {
  padding: 10px;
  border: none;
  border-top: 4px solid var(--everest-white);
  border-bottom: 4px solid var(--everest-white);
}

.vehicleManagerTableWrapper tr:nth-child(2n) {
  background-color: var(--secondary);
}

.vehicleManagerTableWrapper tr:nth-child(2n) td {
  border-color: var(--secondary);
}

.rowIsSelected,
tr:nth-child(2n).rowIsSelected {
  background-color: var(--primary-200);
}

.vehicleManagerTableHeader {
  padding: 10px;
  background-color: var(--secondary);
}

.vehicleManagerTableHeader span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  white-space: nowrap;
  gap: var(--gap);
}

.clickable {
  cursor: pointer;
}

.editableCell,
.editableCellColumnHeader {
  min-width: 130px;
}

.dateTimeWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.125rem 0;
  outline: none;
  border: 2px solid var(--secondary-darker);
  border-radius: var(--radius);
  background-color: var(--everest-white);
}

.dateTimeWrapper input {
  outline: none;
  border-style: none;
  color: var(--grey-700);
}

.dateTimeWrapper:focus-within {
  border-color: var(--primary-500);
}

.dateTimeWrapper label {
  display: block !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: -0.75rem;
  left: 0.5rem;
  max-width: calc(100% - 1.25rem);
  display: flex;
  align-items: center;
  gap: var(--gap-quarter);
  padding: 0 0.25rem;
  background: linear-gradient(0deg, var(--everest-white) 52%, transparent 48%);
  font-weight: 600;
  font-size: 14px;
}

.dateTimeWrapper label:hover {
  color: var(--primary-500);
}

@media (min-width: 600px) {
  .vehicleManagerTableCaption {
    max-width: 560px;
  }
}

@media (min-width: 720px) {
  .vehicleManagerTableCaption {
    max-width: 680px;
  }
}

@media (min-width: 900px) {
  .vehicleManagerTableCaption {
    max-width: none;
  }
}
