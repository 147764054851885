.assignDropdown {
  position: absolute;
  max-width: 20rem;
  border-radius: var(--radius);
  background-color: var(--everest-white);
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 0.15);
  white-space: nowrap;
}

.assignDropdown .dropdownHeader {
  display: flex;
  flex-direction: column;
  gap: var(--gap-quarter);
  padding: 0.75rem;
}

.assignDropdown .assignButtons {
  display: flex;
  gap: var(--gap-quarter);
}

.assignDropdown .options {
  max-height: 14rem;
  overflow-y: auto;
  border-top: 1px solid var(--grey-200);
  border-bottom: 1px solid var(--grey-200);
}

.assignDropdown .emptySearch {
  text-align: center;
  font-weight: 600;
  color: var(--secondary-darkest);
}
