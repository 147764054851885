.react-calendar {
  position: absolute;
  left: -0.125rem;
  width: calc(100vw - 4rem);
  max-width: 16.75rem;
  background: var(--everest-white);
  border-radius: var(--radius);
  font-family: var(--font-sans);
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 0.15);
  padding: 0.75rem;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

.react-calendar button {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 2rem;
}

.react-calendar__navigation__label {
  font-size: 1rem;
  font-weight: 700;
}

.react-calendar__navigation button {
  padding: 0.25rem;
  background: none;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation button:disabled:hover {
  color: inherit;
  cursor: default;
}

.react-calendar__navigation__label:enabled:hover {
  color: var(--primary-500);
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  color: var(--secondary-darker);
}

.react-calendar__navigation__prev-button:enabled:hover,
.react-calendar__navigation__next-button:enabled:hover {
  border-radius: var(--radius-round);
  background-color: var(--grey-100);
  color: var(--secondary-darkest);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font-weight: 600;
  font-size: 0.875rem;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.25rem;
  font-size: 0.75rem;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
}

.react-calendar__month-view__days__day--weekend {
  color: var(--everest-red);
  font-size: 0.75rem;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--secondary-darker);
}

.react-calendar__month-view__days,
.react-calendar__year-view__months {
  gap: var(--gap-quarter);
}

.react-calendar__year-view__months {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1rem 0 !important;
}

.react-calendar__tile {
  max-width: 100%;
  background: none;
  text-align: center;
  padding: 0.375rem 0 !important;
  font-size: 0.75rem;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--grey-100);
  color: var(--primary-800);
}

.react-calendar__month-view__days__day--weekend:enabled:hover,
.react-calendar__month-view__days__day--weekend:enabled:focus {
  color: var(--everest-red);
}

.react-calendar__tile--now {
  outline: 2px solid var(--primary-500) !important;
  outline-offset: -1px;
}

.react-calendar__tile--hasActive {
  background: var(--primary-500);
  color: var(--everest-white);
  outline: none !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--primary-600);
  color: var(--everest-white);
}

.react-calendar__tile--active {
  background: var(--primary-500);
  color: var(--everest-white);
  outline: none !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--primary-600);
  color: var(--everest-white);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media (min-width: 300px) {
  .react-calendar__month-view__weekdays__weekday {
    font-size: 0.875rem;
  }

  .react-calendar__tile {
    font-size: 0.875rem;
  }
}