.checkboxInput label {
  display: flex;
  align-items: center;
  gap: var(--gap-quarter);
  width: 100%;
  padding: 0.25rem 0.75rem;
}


.checkboxInput:hover label {
  color: var(--primary-500);
  cursor: pointer;
  background-color: var(--grey-100);
}

.checkboxInput span {
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  display: none;
}

.checkboxIcon {
  display: flex;
}

.checked {
  color: var(--primary-500);
  font-weight: 600;
}