.customOptionWrapper {
    position: relative;
}

.customOptionWrapper:hover .customOption {
    background-color: var(--primary-400);
    color: var(--everest-white);
}

.customOptionWrapper:hover .selected,
.selected,
.selected:hover {
    background-color: var(--primary-500);
    color: var(--everest-white) !important;
}

.customOption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.5rem;
    width: 100%;
}

.customOption div {
    display: flex;
}

.open.customDateWindow {
    display: flex !important;
}

.customDateWindow {
    display: none !important;
    z-index: 200;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 11rem;
    width: 18rem;
    padding: 1.25rem;
    border-radius: var(--radius-sm);
    background-color: var(--everest-white);
    box-shadow: var(--shadow);
    color: var(--primary-800);
    cursor: default;
}

.customSelected {
    left: 16.5rem;
}

.customDateActions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.customDateWindow form {
    display: flex;
    flex-direction: column;
    gap: var(--gap-three-quarters);
}

/* DATE PICKER STYLES */
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    position: relative;
    display: flex;
    align-items: center;
    border: 2px solid var(--secondary-darker);
    border-radius: var(--radius);
    width: 100%;
    background-color: var(--secondary);
}

.input input {
    border: 0;
    border-radius: var(--radius-sm);
    outline: none;
    line-height: 1;
    padding: 0.5rem;
    padding-right: 1rem;
    background-color: var(--secondary);
}

.hasIcons input {
    padding-left: 0;
}

.input:focus-within {
    border-color: var(--primary-500);
}

.input label {
    position: absolute;
    top: 0.625rem;
    left: 0.75rem;
    padding: 0 0.25rem;
    width: auto;
    white-space: nowrap;
    transform: scale(1);
    transform-origin: top left;
    transition: var(--transition);
    color: var(--secondary-darkest);
    font-weight: 600;
    cursor: text;
    background: linear-gradient(0deg, var(--secondary) 50%, transparent 50%);
}

.hasIcons label {
    left: 2.375rem;
}

.input:focus-within label,
.input .active {
    top: -0.625rem;
    font-size: var(--text-xs);
}

.input:focus-within label {
    color: var(--primary-500);
}

.icon {
    z-index: 50;
    display: flex;
    padding: 0 0.5rem;
    font-size: var(--text-2xl);
    color: var(--secondary-darker);
}

.icon div {
    display: flex;
    cursor: pointer;
}

.clearIcon {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    font-size: var(--text-2xl);
    color: var(--secondary-darker);
    cursor: pointer;
}

.clearIcon div {
    display: flex;
}

.clearIcon:hover {
    color: var(--secondary-darkest);
}

.errMessage {
    margin: 0;
    font-weight: 500;
    word-break: break-word;
    white-space: normal;
}
