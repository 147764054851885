.autocompleteWrapper {
    position: relative;
    width: 80%;
}

.autocompleteWrapper:focus-within .suggestionDropdown {
    display: block;
}

.suggestionDropdown {
    z-index: 100;
    display: none;
    position: absolute;
    top: 3rem;
    width: 100%;
    padding: 0.25rem 0;
    border: 1px solid var(--secondary-dark);
    border-radius: var(--radius-sm);
    background-color: var(--everest-white);
    box-shadow: var(--shadow);
    font-size: var(--text-base);
}

.suggestion {
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.suggestion:hover,
.suggestion.active {
    background-color: var(--secondary);
}

.locationInput {
    position: relative;
    display: flex;
    align-items: center;
    border: 2px solid var(--secondary-darker);
    border-radius: var(--radius);
    background: var(--everest-white);
    width: 80%;
    min-width: 175px;
}


.locationInput input {
    border: 0;
    border-radius: var(--radius);
    outline: none;
    line-height: 1;
    padding: 0.5rem;
    width: 90%;
}

.horizontalInput input {
    width: 100%;
    border: 2px solid var(--grey-600);
    border-radius: var(--radius-sm);
    outline: none;
}

.medium input {
    padding: 0.625rem;
    padding-left: 0.875rem;
    padding-right: 1rem;
    font-size: var(--text-base);
}

.hasIcons input {
    padding-left: 0rem;
}

.icon {
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 2.75rem;
    font-size: var(--text-3xl);
    color: var(--secondary-darkest);
}

.medium .icon {
    min-width: 2.5rem;
    font-size: var(--text-xl);
}