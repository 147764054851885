.content {
    display: flex;
    flex-direction: column-reverse;
    /* fixes z-index issue with datepicker */
}

div .cardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 0;
}

.kpiDateLabels {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.kpiDates {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: var(--text-xs);
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.gridElemSmall {
    grid-column: span 12;
}

.gridElemMedium {
    grid-column: span 12;
}

@media (min-width: 768px) {
    .gridElemSmall {
        grid-column: span 6;
    }

    .gridElemMedium {
        grid-column: span 12;
    }
}

@media (min-width: 1024px) {
    .gridElemSmall {
        grid-column: span 4;
    }

    .gridElemMedium {
        grid-column: span 6;
    }
}

/* OPTIONS/ACTIONS STYLES */
.CSVDownloader {
    border: none;
    padding: 0.5rem 1rem;
    width: fit-content;
    font-weight: 600;
    background-color: var(--primary-500);
    color: var(--everest-white);
    border-radius: var(--radius-sm);
    display: flex;
    align-items: center;
    justify-content: center;
}

.CSVDownloader:hover {
    background-color: var(--primary-600);
    cursor: pointer;
}

.actionsSkeleton,
.actionsWrapper,
.printSkeleton {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.actionsSkeleton>span {
    height: 32px;
}

.optionsWrapper,
.optionsRowWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
}

.toggle {
    display: flex;
    flex-wrap: wrap;
    border-radius: var(--radius-sm);
    background-color: var(--secondary-dark);
    white-space: nowrap;
    width: fit-content;
}

.toggle button {
    width: 100%;
}

.r360toggleContainer {
    display: flex;
    margin-bottom: 1rem;
}

.optionsDropdownWrapper {
    position: relative;
}

.dateSpanButton {
    padding-bottom: 1rem;
}

.customSelected {
    width: 16.5rem;
}

.valueContainer {
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
    padding-right: 0.5rem;
    border: none;
    border-radius: var(--radius-sm);
    background-color: var(--primary-500);
    color: var(--everest-white);
    font-weight: 600;
    cursor: pointer;
}

.valueContainer:hover {
    background-color: var(--primary-600);
}

.valueContainer div {
    display: flex;
    font-size: var(--text-xl);
}

.dropdownContainer {
    z-index: 100;
    display: none;
    position: absolute;
    top: 1.25rem;
    width: max-content;
    font-size: var(--text-base);
}

.dropdownOptions {
    list-style: none;
    padding: 0;
    box-shadow: var(--shadow);
    background-color: var(--everest-white);
    border-radius: var(--radius-sm);
    max-height: 400px;
    overflow: auto;
    padding: 0.25rem 0;
}

.dropdownOption {
    padding: 0.375rem 0.75rem;
    border: none;
    color: var(--secondary-darkest);
    font-weight: 600;
    cursor: pointer;
}

.exportIcon {
    display: flex;
    font-size: var(--text-xl);
}

.overlay,
.exportOverlay {
    z-index: 99;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.open {
    display: block;
}

.exportOverlay {
    z-index: 1000;
    background-color: var(--overlay);
}

.exportModal {
    z-index: 1001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    flex-direction: column;
    gap: var(--gap-double);
    max-height: calc(100vh - 2rem);
    width: 100%;
    max-width: 40rem;
    padding: 1.5rem;
    border-radius: var(--radius);
    background-color: var(--secondary);
    box-shadow: var(--shadow);
    overflow-x: hidden;
    overflow-y: auto;
}

.openExport {
    display: flex;
}

.exportModal h1,
.exportModal h2,
.exportModal ul {
    margin: 0;
    padding: 0;
}

.exportModal h2 {
    margin-bottom: 0.75rem;
    font-family: var(--font-sans);
    font-weight: 600;
}

.exportModal ul {
    display: flex;
    flex-direction: column;
    gap: var(--gap-half);
    list-style: none;
    width: min-content;
    white-space: nowrap;
}

.exportModal li {
    display: flex;
    align-items: center;
    gap: var(--gap-half);
    font-weight: 600;
    cursor: pointer;
}

.exportModal li:hover,
.exportModal li:hover div {
    color: var(--primary-500);
}

.exportModal li div {
    display: flex;
    font-size: var(--text-xl);
    color: var(--secondary-darkest);
}

.exportModal li .selectedExport {
    color: var(--primary-500);
}

.exportModalButtons {
    display: flex;
    flex-direction: column-reverse;
    gap: var(--gap-half);
}

.exportButtons {
    display: flex;
    flex-direction: column;
    gap: var(--gap-half);
}

.option {
    padding: 0.375rem 0.75em;
    border: none;
    border-radius: var(--radius-sm);
    background-color: var(--secondary-dark);
    color: var(--grey-700);
    font-weight: 600;
    font-size: var(--text-base);
    cursor: pointer;
}

.dropdownOption:hover,
.option:hover {
    background-color: var(--primary-400);
    color: var(--everest-white);
}

.selected,
.selected:hover {
    background-color: var(--primary-500);
    color: var(--everest-white);
}

/* CHART CARD STYLES */
.chartCard {
    /* z-index: 0; */
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    margin-bottom: 1rem;
    padding: 1.25rem;
    padding-bottom: 1rem;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    background-color: var(--secondary);
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap);
    white-space: nowrap;
}

.narrowHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap);
    white-space: nowrap;
}

.secondHeader {
    display: flex;
    align-items: center;
}

.heading {
    font-size: var(--text-2xl);
    margin: 0;
}

.legendWrapper {
    display: flex;
    margin-left: auto;
    gap: var(--gap-double);
}

.legendLabel {
    display: flex;
    align-items: center;
    gap: var(--gap-quarter);
    color: var(--grey-700);
}

.legendLabel div {
    display: flex;
    font-size: var(--text-xl);
}

.legendLabel span {
    font-size: var(--text-base);
    font-weight: 600;
}

.chartContainer {
    position: relative;
    height: 20rem;
}

.avgRevChart {
    height: 100%;
}

.kpi {
    color: var(--primary-800);
    font-size: var(--text-3xl);
    font-weight: 700;
    margin: 18% auto;
    display: flex;
    align-items: center;
}

.nodata {
    color: var(--secondary-darkest);
    font-size: var(--text-4xl);
    font-weight: 700;
    margin: 18% auto;
}

/* REACT-TO-PRINT STYLES */
.hidden {
    height: 0;
    overflow: hidden;
    visibility: hidden;
}

/* SNAPSHOT STYLES */
.snapshot {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: var(--text-6xl);
    margin-bottom: 1vh;
}

.snapshot .phrase {
    grid-column: span 3;
}

.snapshot .phraseData {
    justify-self: end;
}

@media (min-width: 420px) {
    .heading {
        font-size: 1.75rem;
    }

    .exportModalButtons {
        gap: var(--gap);
    }

    .exportButtons {
        flex-direction: row;
        gap: var(--gap);
    }

    .toggle button {
        width: min-content;
    }
}

@media (min-width: 480px) {
    .exportModalButtons {
        flex-direction: row;
        justify-content: space-between;
    }

    .exportButton,
    .cancelButton {
        width: min-content;
    }
}

@media (min-width: 560px) {
    .optionsRowWrapper {
        flex-direction: row;
    }
}

@media (min-width: 896px) {
    .optionsWrapper {
        flex-direction: row;
    }

    .actionsSkeleton,
    .actionsWrapper {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .exportSkeleton,
    .exportButton {
        margin-left: auto;
    }
}

@media (min-width: 1200px) {
    .header {
        flex-direction: row;
        align-items: center;
    }
}