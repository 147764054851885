.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--everest-white);
}

.content h1 {
    margin-bottom: 0;
    font-size: 4rem;
}