.react-datetime-picker {
  display: inline-flex;
  position: relative;
  background-color: var(--everest-white);
  padding: 0.625rem 0.375rem 0.625rem 0.625rem;
  border-radius: var(--radius);
  font-size: 0.875rem;
  /* set for Vehicle Manager table cell */
  min-width: 215px;
}

.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-datetime-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  display: flex;
}

.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
  width: 5px;
}

.react-datetime-picker__inputGroup__leadingZero {
  padding: 1px 0;
}

.react-datetime-picker__inputGroup__input {
  /* height: calc(100% - 2px); */
  position: relative;
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  text-align: center;
  box-sizing: border-box;
  appearance: textfield;
  -moz-appearance: textfield;
}

.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__input:invalid {
  min-width: 1.25rem;
  background: var(--everest-red-translucent);
  padding: 1px;
}

.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  appearance: menulist;
  -moz-appearance: menulist;
}

.react-datetime-picker__button {
  position: absolute;
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__clear-button {
  top: 50%;
  right: 2.125rem;
  height: 1.75rem;
  color: var(--secondary-darkest);
  transform: translate(0, -50%);
}

.react-datetime-picker__clear-button:hover {
  color: var(--secondary-black);
  border-radius: var(--radius-round);
  background-color: var(--secondary-dark);
}

.react-datetime-picker__calendar-button {
  top: 50%;
  right: 0.375rem;
  height: 1.75rem;
  color: var(--secondary-darkest);
  transform: translate(0, -50%);
}

.react-datetime-picker__calendar-button:hover {
  color: var(--secondary-black);
  border-radius: var(--radius-round);
  background-color: var(--secondary-dark);
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #0078d7;
}

.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}

.react-datetime-picker__button svg {
  display: inherit;
}

.react-datetime-picker__calendar {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

.react-datetime-picker__calendar--closed {
  display: none;
}