@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --primary-100: #E1F3F3;
  --primary-200: #CBF3F5;
  --primary-300: #ABE1E3;
  --primary-400: #72D2D6;
  --primary-500: #03989E;
  --primary-500-transparent: rgba(3, 152, 158, .75);
  --primary-500-translucent: #03989E1E;
  --primary-500-translucent-dark: #03989E3C;
  --primary-600: #026367;
  --primary-600-translucent: #0263671E;
  --primary-700: #024D50;
  --primary-800: #012728;

  --secondary: #FBF7EF;
  --secondary-dark: #EDE5D5;
  --secondary-darker: #C8C4BD;
  --secondary-darkest: #888;
  --secondary-darkest-translucent: #8888881E;
  --secondary-black: #525252;

  --grey-50: #fafafa;
  --grey-100: #f5f5f5;
  --grey-200: #eee;
  --grey-300: #e0e0e0;
  --grey-400: #bdbdbd;
  --grey-500: #9e9e9e;
  --grey-600: #757575;
  --grey-700: #616161;
  --grey-800: #424242;
  --grey-900: #212121;

  --blue-50: #e7edf5;
  --blue-100: #c3d2e6;
  --blue-200: #9BB4D6;
  --blue-300: #7395c5;
  --blue-400: #557fb8;
  --blue-500: #3768AC;
  --blue-600: #3160a5;
  --blue-700: #2a559b;
  --blue-800: #234b92;
  --blue-900: #163a82;

  --green-50: #E6F0E4;
  --green-100: #C1DBBB;
  --green-200: #98C38E;
  --green-300: #6FAA61;
  --green-400: #50983F;
  --green-400-translucent: #50983F3F;
  --green-500: #31861D;
  --green-600: #2C7E1A;
  --green-700: #257315;
  --green-800: #1F6911;
  --green-900: #13560A;

  --orange-50: #FFF3E0;
  --orange-100: #FFE0B2;
  --orange-200: #FFCC80;
  --orange-300: #FFB74D;
  --orange-400: #FFA726;
  --orange-500: #FF9800;
  --orange-500-translucent: #ff99002b;
  --orange-600: #FB8C00;
  --orange-700: #F57C00;
  --orange-800: #EF6C00;
  --orange-900: #BC3D00;

  --brown-50: #EFEBE9;
  --brown-100: #D7CCC8;
  --brown-200: #BCAAA4;
  --brown-300: #A1887F;
  --brown-400: #8D6E63;
  --brown-500: #795548;
  --brown-600: #6D4C41;
  --brown-700: #5D4037;
  --brown-800: #4E342E;
  --brown-900: #3E2723;

  --everest-red-light: #F4C4C2;
  --everest-red-translucent: #D6110A1E;
  --everest-red: #D6110A;
  --everest-red-dark: #9c0000;
  --everest-green: #31861D;
  --everest-white: #FFF;
  --everest-yellow: #FEBA7D;
  --everest-black: #363636;

  --graph-orange: rgb(235, 107, 52);
  --graph-orange-transparent: rgba(235, 107, 52, .5);
  --graph-green: rgb(54, 150, 35);
  --graph-green-transparent: rgba(54, 150, 35, .5);
  --graph-purple-dark: rgb(66, 40, 128);
  --graph-purple-dark-transparent: rgba(66, 40, 128, .5);
  --graph-aqua-dark: rgb(40, 125, 128);
  --graph-aqua-dark-transparent: rgba(40, 125, 128, .5);
  --graph-gold: rgb(191, 156, 67);
  --graph-gold-transparent: rgba(191, 156, 67, .5);
  --graph-salmon-dark: rgb(186, 73, 92);
  --graph-salmon-dark-transparent: rgba(186, 73, 92, .5);
  --graph-blue: rgb(39, 15, 148);
  --graph-blue-transparent: rgba(39, 15, 148, .5);
  --graph-green-pale: rgb(194, 227, 168);
  --graph-green-pale-transparent: rgba(194, 227, 168, .5);
  --graph-pink-light: rgb(218, 157, 224);
  --graph-pink-light-transparent: rgba(218, 157, 224, .5);
  --graph-red-dark: rgb(105, 5, 7);
  --graph-red-dark-transparent: rgba(105, 5, 7, .5);
  --graph-light-blue: rgb(53, 162, 235);
  --graph-light-blue-transparent: rgba(53, 162, 235, 0.5);


  --overlay: rgba(0, 0, 0, 0.3);
  --tooltip: rgba(0, 0, 0, 0.8);

  /* Font sizes */
  --text-xs: 0.75rem;
  /* 12px */
  --text-sm: 0.875rem;
  /* 14px */
  --text-base: 1rem;
  /* 16px */
  --text-lg: 1.125rem;
  /* 18px */
  --text-xl: 1.25rem;
  /* 20px */
  --text-2xl: 1.5rem;
  /* 24px */
  --text-3xl: 2rem;
  /* 32px */
  --text-4xl: 2.25rem;
  /* 36px */
  --text-5xl: 3rem;
  /* 36px */
  --text-6xl: 3.5rem;
  /* 36px */

  /* Typography */
  --font-head: 'Baskerville', serif;
  --font-sans: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  /* Spacing */
  --gap-eighth: 0.125rem;
  --gap-quarter: 0.25rem;
  --gap-half: 0.5rem;
  --gap-three-quarters: 0.75rem;
  --gap: 1rem;
  --gap-double: 2rem;
  --gap-md: 1.5rem;
  --gap-lg: 2rem;
  --gap-xl: 4rem;

  /* Radius */
  --radius-sm: 5px;
  --radius: 8px;
  --radius-lg: 15px;
  --radius-round: 9999px;

  /* Shadows */
  --shadow-sm: 0 5px 12px -1px rgb(0, 0, 0, 0.15);
  --shadow: 0 5px 12px -1px rgb(0, 0, 0, 0.2);
  --shadow-md: 0 5px 15px -2px rgb(0, 0, 0, 0.25);

  /* Transitions */
  --transition: 160ms ease-in-out;
}

body {
  margin: 0;
  font-family: var(--font-sans);
  font-size: var(--text-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: var(--primary-800);
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-family: var(--font-head);
  font-weight: 400;
}

h1 {
  font-size: var(--text-3xl);
}

h2 {
  font-size: var(--text-2xl);
}

h3 {
  font-size: var(--text-xl);
}

a {
  text-decoration: none;
}

img {
  cursor: default;
}

input {
  border-style: solid;
  outline: none;
}

input[type=range] {
  -webkit-appearance: none;
  /* Hides the slider so that custom slider can be made */
  width: 100%;
  /* Specific width is required for Firefox. */
  background: transparent;
  /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
  /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range] {
  height: 5px;
  /* background-color: aqua; */
  border-width: 0;
  --thumb-border: 1px solid var(--primary-800);
  --thumb-height: 26px;
  --thumb-width: 26px;
  --thumb-border-radius: 15px;
  --thumb-background: var(--everest-white);
  --thumb-box-shadow: 1px 1px 1px var(--primary-800), 0px 0px 1px #0d0d0d;
  --track-width: 100%;
  --track-height: 8.4px;
  --track-box-shadow: 1px 1px 1px var(--primary-800), 0px 0px 1px #0d0d0d;
  --track-background: aqua;
  --track-border-radius: 5px;
  --track-border: 0.2px solid #010101;
  --track-background-hover: #367ebd;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: var(--thumb-border);
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: var(--thumb-border-radius);
  background: var(--thumb-background);
  cursor: pointer;
  margin-top: -14px;
  /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: var(--thumb-box-shadow);
}

/* Firefox */
input[type=range]::-moz-range-thumb {
  border: var(--thumb-border);
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: var(--thumb-border-radius);
  background: var(--thumb-background);
  cursor: pointer;
  box-shadow: var(--thumb-box-shadow);
}

/* IE */
input[type=range]::-ms-thumb {
  border: var(--thumb-border);
  height: var(--thumb-height);
  width: var(--thumb-width);
  border-radius: var(--thumb-border-radius);
  background: var(--thumb-background);
  cursor: pointer;
  box-shadow: var(--thumb-box-shadow);
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-runnable-track {
  width: var(--track-width);
  height: var(--track-height);
  cursor: pointer;
  box-shadow: var(--track-box-shadow);
  background: var(--track-background);
  border-radius: var(--track-border-radius);
  border: var(--track-border);
}

/* Special styling for WebKit/Blink */
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--track-background-hover);
}

/* Firefox */
input[type=range]::-moz-range-track {
  width: var(--track-width);
  height: var(--track-height);
  cursor: pointer;
  box-shadow: var(--track-box-shadow);
  background: var(--track-background);
  border-radius: var(--track-border-radius);
  border: var(--track-border);
}

/* IE */
input[type=range]::-ms-track {
  width: var(--track-width);
  height: var(--track-height);
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

/* IE */
input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: var(--track-border);
  border-radius: var(--track-border-radius);
  box-shadow: var(--track-box-shadow);
}

/* IE */
input[type=range]:focus::-ms-fill-lower {
  background: var(--track-background);
}

/* IE */
input[type=range]::-ms-fill-upper {
  background: var(--track-background);
  border: var(--track-border);
  border-radius: var(--track-border-radius);
  box-shadow: var(--track-box-shadow);
}

/* IE */
input[type=range]:focus::-ms-fill-upper {
  background: var(--track-background-hover);
}

/* Hides autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px var(--everest-white) inset !important;
  box-shadow: 0 0 0px 1000px var(--everest-white) inset !important;
}

.container {
  max-width: 74rem;
  margin: 0 auto;
  /* overflow: auto; */
  padding: 0 1rem;
  position: relative;
}

.err {
  color: var(--everest-red);
  font-size: var(--text-base);
}

.success {
  color: var(--everest-green);
  font-size: var(--text-base);
  font-weight: 600;
}

/* Hide password reveal icon for Edge */
::-ms-reveal {
  display: none
}

@media (max-width: 1280px) {
  body {
    font-size: var(--text-base);
  }

  h1 {
    font-size: var(--text-2xl);
  }

  .err,
  .success {
    font-size: var(--text-sm);
  }
}