.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 48rem;
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: var(--gap);
    margin-bottom: 1.5rem;
}

.header h1 {
    margin: 0;
}

.heading {
    margin-top: 0;
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--gap-three-quarters);
}

.icon {
    font-size: var(--text-3xl);
    color: var(--secondary-darker);
}

.icon:hover {
    color: var(--secondary-darkest);
    cursor: pointer;
}

.criteria {
    display: flex;
    flex-direction: column;
}

.criteria p {
    font-weight: 500;
}

.criteria ul {
    margin-bottom: 0;
}

.customRates {
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}

@media (max-width:1280px) {
    .section {
        align-self: center;
    }
}
