.modal {
    z-index: 1002;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: var(--gap-double);
    max-height: calc(100vh - 2rem);
    width: 91.666667%;
    max-width: 40rem;
    padding: 1.5rem;
    border-radius: var(--radius);
    background-color: var(--secondary);
    display: none;
    overflow-y: hidden;
}

.wide {
    max-width: 52rem;
}

.overlay {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--overlay);
    display: none;
}

.open.modal,
.open.overlay {
    display: flex;
}

.actions {
    display: flex;
    gap: var(--gap);
    justify-content: flex-end;
}

.fullWidth.actions > * {
    max-width: 100%;
}

.header {
    display: flex;
    flex-direction: column;
}

.body {
    overflow-y: auto;
}

.body::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

.body::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    background-color: var(--secondary-dark);
    border-radius: var(--radius);
}

.body::-webkit-scrollbar-thumb:hover {
    border: 2px solid transparent;
    background-color: var(--secondary-darker);
}