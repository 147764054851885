.whitelist {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.sort {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.card {
    background-color: var(--secondary);
    box-shadow: var(--shadow-sm);
    border-radius: var(--radius);
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card > h2 {
    margin: 0;
}

.card > :not(.allowListItems) {
    padding-left: 24px;
    padding-right: 24px;
}

.searchbar {
    width:300px;
    position: relative;
    height: 34px;
    font-size: var(--text-sm);
    border-radius: var(--radius);
}

.search {
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: 0 16px 0 24px;
}

.icon {
    position: absolute;
    top: 0;
    left: 8px;
    z-index: 2;
    transform: translate(0, 70%);
}

.error {
    color: var(--everest-red);
}

.error span {
    margin-top: 1rem;
}

