/* General */
span {
    position: relative;
    display: flex;
}

button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--gap-quarter);
    white-space: nowrap;
}

.justifySpaceBetween {
    justify-content: space-between;
}

/* Button States */
button:hover {
    cursor: pointer;
}

button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

button:disabled.loading {
    cursor: progress;
}

/* Button UI Types */
.cta { /* Call-To-Action, Filled */
    border: none;
}

.ghost { /* Outline, Empty, Hollow */
    border: 2px solid var(--secondary-darkest);
    background-color: transparent;
    color: var(--secondary-darkest);
}

.ghost:hover {
    background-color: var(--secondary-darkest-translucent);
}

.text {
    border: none;
    background-color: transparent;
    color: var(--secondary-darkest);
}

.fab { /* Floating Action Button */
    box-shadow: var(--shadow-md);
    border-style: none;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: fit-content;
    padding: 1.125rem;
    font-weight: 700;
}

.icon {
    border: none;
    background-color: transparent;
    color: var(--secondary-darkest);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    font-size: var(--text-2xl);
}

/* Button Border Radius */
.radius {
    border-radius: var(--radius);
}

.radiusSm {
    border-radius: var(--radius-sm);
}

.radiusRound {
    border-radius: var(--radius-round);
}

/* Button Colors */
.cta.primary:hover,
.fab.primary:hover {
    background-color: var(--primary-600);
}

.cta.primary,
.fab.primary,
.primary:disabled:hover {
    background-color: var(--primary-500);
    color: var(--everest-white);
}

.ghost.primary:hover {
    background-color: var(--primary-500-translucent);
}

.ghost.primary {
    border-color: var(--primary-500);
    color: var(--primary-500);
}

.cta.primaryDark:hover,
.fab.primaryDark:hover {
    background-color: var(--primary-700);
}

.cta.primaryDark,
.fab.primaryDark,
.primaryDark:disabled:hover {
    background-color: var(--primary-600);
    color: var(--everest-white);
}

.ghost.primaryDark:hover {
    background-color: var(--primary-600-translucent);
}

.ghost.primaryDark {
    border-color: var(--primary-600);
    color: var(--primary-600);
}

.cta.secondaryDark {
    background-color: var(--secondary-darker);
}

.cta.secondaryDark:hover {
    background-color: var(--secondary-darkest);
    color: var(--secondary);
}

.cta.blue,
.fab.blue {
    background-color: var(--blue-500);
    color: var(--everest-white);
}

.cta.blue:hover,
.fab.blue:hover {
    background-color: var(--blue-700);
}

.cta.red,
.fab.red  {
    background-color: var(--everest-red);
    color: var(--everest-white);
}

.ghost.red {
    border-color: var(--everest-red);
    color: var(--everest-red);
}

.cta.red:hover,
.fab.red:hover {
    background-color: var(--everest-red-dark);
}

.ghost.red:hover {
    background-color: var(--everest-red-translucent);
}

.icon.red:hover,
.text.red:hover {
    background-color: var(--everest-red-light);
    color: var(--everest-red);
}

.icon.primary:hover,
.text.primary:hover {
    background-color: var(--primary-300);
    color: var(--primary-700);
}

.text.secondaryDark {
    color: var(--secondary-darkest);
}

.icon.secondaryDark:hover,
.text.secondaryDark:hover {
    background-color: var(--secondary-dark);
}

.text.secondaryDark:disabled:hover {
    background-color: transparent;
}

.icon.secondaryDarker,
.text.secondaryDarker {
    color: var(--secondary-darker);
}

.icon.secondaryDarker:hover,
.text.secondaryDarker:hover {
    background-color: var(--secondary);
    color: var(--secondary-darkest);
}

/* Button Shadow */
.shadow {
    box-shadow: var(--shadow);
}

.shadowSm {
    box-shadow: var(--shadow-sm);
}

.shadowMd {
    box-shadow: var(--shadow-md);
}

/* Button Sizes */
.cta.xsmall,
.ghost.xsmall,
.text.xsmall,
.fab.xsmall {
    width: fit-content;
    padding: 0.1875rem 0.5rem;
    align-items: center;
    font-weight: 600;
    font-size: var(--text-sm);
}

.cta.small,
.ghost.small,
.text.small,
.fab.small {
    width: fit-content;
    padding: 0.375rem 0.5rem;
    align-items: center;
    font-weight: 600;
    font-size: var(--text-base);
}

.cta.small,
.ghost.small,
.fab.small {
    padding: 0.375rem .75rem;
}

.icon.small {
    width: 1.875rem;
    height: 1.875rem;
    font-size: var(--text-lg);
}

.cta.medium,
.ghost.medium,
.text.medium,
.fab.medium {
    padding: 0.5rem 1rem;
    width: fit-content;
    align-items: center;
    font-weight: 600;
}

.cta.large,
.ghost.large,
.text.large,
.fab.large {
    height: 2.75rem;
    padding: 1rem 1.5rem;
    font-weight: 700;
}

.icon.large {
    font-size: var(--text-3xl);
}

.icon.xlarge {
    width: 3rem;
    height: 3rem;
    font-size: var(--text-3xl);
}

.fullwidth,
.cta.fullwidth,
.ghost.fullwidth,
.text.fullwidth,
.fab.fullwidth,
.icon.fullwidth {
    width: 100%;
}

/* Tooltip Styling */
.tooltip {
    visibility: hidden;
    opacity: 0;
    z-index: 100;
    position: absolute;
    top: 80%;
    left: 80%;
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius-sm);
    background-color: var(--tooltip);
    color: var(--grey-100);
    font-size: var(--text-sm);
    box-shadow: var(--shadow-sm);
    white-space: nowrap;
    transition: opacity 150ms ease-in;
}

button:hover ~ .tooltip,
.tooltip:hover {
    visibility: visible;
    opacity: 1;
    transition: opacity 150ms ease-in;
}

@media (max-width: 480px) {
    .fab span {
        display: none;
    }
}

@media (max-width: 768px) {
    .tooltip {
        display: none;
    }
}
