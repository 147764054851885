.previewBar {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 2.125rem;
    padding: 0.25rem 1rem;
    background-color: var(--primary-400);
}

.previewWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 74rem;
}

.previewWrapper button,
.optionWrapper  {
    display: none;
}

.previewSettings {
    display: flex;
    align-items: center;
    gap: var(--gap-half);
}

.wide,
.wide .previewWrapper {
    max-width: 1920px;
}

.optionWrapper {
    position: relative;
}

.dropdown {
    z-index: 100;
    position: absolute;
    top: 1.875rem;
    left: 0;
    display: none;
    border-radius: var(--radius-sm);
    background-color: var(--everest-white);
    box-shadow: var(--shadow-md);
}

.overlay {
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: 100vh;
}

.open .dropdown,
.open .overlay {
    display: flex;
}

.singleSelectOptions,
.checkboxOptions,
.inputOption {
    margin: 0;
    padding: 0.25rem 0;
    min-width: 6rem;
    list-style: none;
    font-size: var(--text-base);
}

.inputOption {
    display: flex;
    flex-direction: column;
    gap: var(--gap-three-quarters);
    padding: 0.75rem;
}

.inputActions {
    display: flex;
    gap: var(--gap-double);
}

.option {
    display: flex;
    align-items: center;
    gap: var(--gap-half);
    padding: 0.25rem 0.75rem;
    font-weight: 600;
    color: var(--secondary-darkest);
    white-space: nowrap;
    cursor: pointer;
}

.option div {
    display: flex;
    font-size: var(--text-lg);
}

.singleSelectOptions .option:hover {
    background-color: var(--primary-400);
    color: var(--everest-white);
}

.singleSelectOptions .selected.option {
    background-color: var(--primary-500);
    color: var(--everest-white);
}

.checkboxOptions .option:hover {
    color: var(--primary-500);
}

.checkboxOptions .checked.option div {
    color: var(--primary-500);
}

@media(min-width: 768px) {
    .previewWrapper {
        justify-content: space-between;
    }
    .previewWrapper button,
    .optionWrapper {
        display: flex;
    }
}