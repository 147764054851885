.content {
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 100%;
    max-width: 120rem;
}

/* USER TABLE COMPONENTS */
.container {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 6rem);
}

.previewOn.container {
    max-height: calc(100vh - 8rem);
}

.skeletons {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 120rem;
    max-height: calc(100vh - 6.5rem);
    overflow: hidden;
    gap: var(--gap-quarter);
}

.userTable {
    display: block;
    width: 100%;
    overflow-x: auto;
}

.userTable::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

.userTable::-webkit-scrollbar-track {
    padding: 1px 0;
    border: 2px solid var(--secondary);
}

.userTable::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    background-clip: padding-box;
    background-color: var(--secondary-dark);
    border-radius: var(--radius);
}

.userTable::-webkit-scrollbar-thumb:hover {
    border: 2px solid transparent;
    background-color: var(--secondary-darker);
}

.tableHeader {
    z-index: 10;
    position: relative;
    max-width: 120rem;
    background-color: var(--secondary);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
}

.scroller {
    width: 100%;
    overflow-x: hidden;
}

.heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--gap-half);
    width: 100%;
    padding: 1rem 1.5rem;
}

.userTitle,
.title {
    display: flex;
    gap: var(--gap-half);
}

.userTitle {
    gap: var(--gap);
    align-items: center;
}

.title {
    display: flex;
    flex-direction: column;
}

.title * {
    margin: 0;
}

.filterSearchRow {
    display: flex;
    gap: var(--gap-half);
    flex-direction: column;
}

.count,
.userTable .title span,
.bubble {
    width: min-content;
    padding: 0.375rem 0.75rem;
    white-space: nowrap;
    border-radius: var(--radius-round);
    background-color: var(--secondary-dark);
    color: var(--grey-600);
    font-size: var(--text-xs);
    font-weight: 700;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-half);
}

.labels,
.userRow {
    display: grid;
    grid-template-columns: 22rem 15rem 7rem 7rem 18rem 10rem;
    align-items: center;
    font-size: var(--text-sm);
}

.labels {
    width: 100%;
    min-width: 119rem;
    padding: 0.75rem 1.5rem;
    border-top: 3px solid var(--secondary-dark);
    font-weight: 700;
}

.userRow {
    align-items: center;
    width: 100%;
    min-width: 119rem;
    /* overflow: hidden; */
    padding: 0.875rem 1.5rem;
    border-radius: var(--radius) 0 0 var(--radius);
}

.userRow:nth-child(2n) {
    background-color: var(--secondary);
}

.userRow:last-child {
    margin-bottom: 1rem;
}

.username {
    display: flex;
    flex-direction: column;
}

.username span:first-child {
    font-weight: 700;
}

.productsWrapper {
    display: flex;
    align-items: center;
    gap: var(--gap-quarter);
}

.productCount {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border-radius: var(--radius-round);
    background-color: var(--secondary-dark);
    color: var(--grey-600);
    font-size: var(--text-xs);
    font-weight: 700;
}

.products {
    display: flex;
    gap: var(--gap-quarter);
    cursor: grab;
}

.actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: var(--gap-half);
    padding-right: 0.5rem;
}

.previewSpan {
    display: none;
}

@media (min-width: 420px) {
    .buttons {
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 560px) {
    .filterSearchRow {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .title {
        flex-direction: row;
        gap: var(--gap);
    }
    .previewSpan {
        display: inherit;
    }
}

@media (min-width: 1024px) {
    .heading {
        flex-direction: row;
    }
}
