.background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--primary-500);
}

.homeMenuSpan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap-half);
    width: calc(50% + 62.08px);
}

.menuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 2.375rem;
    width: 2.375rem;
    background-color: transparent;
    border-radius: var(--radius-round);
    font-size: var(--text-2xl);
    color: var(--everest-white);
    cursor: pointer;
}

.menuButton div {
    display: flex;
}

.menuButton:hover {
    background-color: var(--primary-dark);
}

.navbarWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 74rem;
    height: 3rem;
}

.navWrapper {
    display: flex;
    align-items: center;
    height: 100%;
}

.navTabs {
    display: none !important;
}

.wide {
    max-width: 1920px;
}

.fullPage {
    max-width: 100%;
}

.navbar h2,
.sidenav h2 {
    margin: 0;
}

.navbar a {
    color: inherit;
    text-decoration: none;
}

.navbar ul {
    list-style: none;
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navbar li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    color: var(--everest-white);
    text-wrap: nowrap;
}

.navbar li:hover {
    background: var(--primary-600);
    cursor: pointer;
}

.navbar img {
    height: 1.875rem;
    cursor: pointer;
}


.navbar .active li {
    background: var(--primary-600);
}

.navbar .link {
    height: 100%;
}

.profileWrapper {
    position: relative;
    margin-left: 1rem;
}

.sidenav {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    height: 100%;
    padding: 4rem 1rem 0 1rem;
    background-color: var(--secondary);
    box-shadow: var(--shadow);
    width: 100%;
}

.sidenav ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: var(--gap-half);
    width: 100%;
}

.sidenav li {
    display: flex;
    align-items: center;
    width: 100%;
    height: min-content;
    padding: 0.625rem 1rem;
    border-radius: var(--radius);
    color: var(--secondary-darkest);
}

.sidenav li:hover,
.sidenav .active li {
    box-shadow: var(--shadow-sm);
    background: var(--primary-500);
    color: var(--everest-white);
}

.sidenav .link li {
    display: flex;
    align-items: center;
    gap: var(--gap);
}

.sidenav .link li div {
    display: flex;
    font-size: var(--text-2xl);
}

.overlay {
    z-index: 998;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--overlay);
}

.openSidenav {
    display: flex;
}

.previewOn.sidenav {
    padding-top: 6rem;
}

.navTabs h2 {
    font-size: 21px;
}

@media (min-width: 420px) {
    .sidenav {
        width: auto;
    }

    .sidenav li {
        width: 15rem;
    }
}

@media (min-width: 900px) {
    .menuButton {
        display: none;
    }

    .navTabs {
        display: flex !important;
    }
}

@media (min-width: 1920px) {
    .navTabs h2 {
        font-size: var(--text-2xl);
    }

}