/* VEHICLE MANAGER TABLE */

.ag-theme-vehicle-manager {
  --ag-borders: solid 1px;
  background-color: #1a1625;
}

.ag-theme-vehicle-manager {

  --ag-foreground-color: var(--grey-700);
  --ag-background-color: rgba(255, 255, 255, 0);
  --ag-header-foreground-color: #908d96;
  --ag-data-color: #76737e;
  /* Header Text color */
  /* --ag-borders: none; */
  --ag-theme-size: 6px;
  --ag-row-height: calc(var(--ag-theme-size) * 7);
  --ag-header-height: calc(var(--ag-theme-size) * 8);
  --ag-list-item-height: calc(var(--ag-theme-size) * 4);
  --ag-font-family: var(--font-sans);
  box-shadow: var(--shadow);
  --ag-card-shadow: none;
  --ag-popup-shadow: none;
}

.ag-theme-vehicle-manager .ag-input-field-input,
.ag-theme-vehicle-manager .ag-text-area-input {
  background-color: #46424f;
  color: #908d96;
  padding: 10px;
}

.ag-theme-vehicle-manager .ag-header {
  --ag-borders: solid 1px;
  --ag-border-color: #46424f;
}

.ag-theme-vehicle-manager .ag-header-cell-resize {
  background-color: #46424f;
  width: .4em;
  height: 60%;
  transform: translate(0, 35%);
}

.ag-theme-vehicle-manager .ag-row-odd {
  background-color: #221d2f;
  box-shadow: inset 30px 0 20px -20px #1a1625, inset -30px 0 20px -20px #1a1625;
}

.ag-theme-vehicle-manager .ag-root-wrapper {
  border: none !important;
}

.ag-theme-vehicle-manager .ag-header-group-cell {
  --ag-cell-horizontal-padding: 7px;
}

.ag-theme-vehicle-manager .ag-header-cell {
  font-size: 11px;
  --ag-cell-horizontal-padding: 7px;
}

.ag-theme-vehicle-manager .ag-header-cell-label {
  display: flex;
  justify-content: left;
}

.ag-theme-vehicle-manager .ag-cell-editable .ag-cell {
  --ag-cell-horizontal-padding: 0px;
}

.ag-theme-vehicle-manager .ag-cell-value {
  font-size: 11px;
  text-align: left;
  --ag-cell-horizontal-padding: 7px;
}

.ag-theme-vehicle-manager .ag-header-group-cell {
  font-size: 18px;
}

.ag-theme-vehicle-manager .ag-popup-editor span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.ag-theme-vehicle-manager .ag-popup-editor span:hover {
  background-color: #CBF3F5;
}

/* .ag-root-wrapper {
  border-radius: var(--radius);
} */