.slogan {
    display: none;
}

.slogan h1, 
.slogan p {
    color: var(--everest-white);
    margin: 5px 0;
}

.slogan p {
    font-size: var(--text-2xl);
    font-weight: 600;
}

@media (min-width: 1024px) {
    .slogan {
        display: block;
    }
}
