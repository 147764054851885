.wrapper {
    z-index: 1000;
    position: absolute;
    top: 2.75rem;
    right: 0;
    display: none;
    padding: 0.75rem 0;
    background: var(--secondary);
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    width: 17rem;
}

.show.wrapper {
    display: flex;
    flex-direction: column;
}

.wrapper ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
}

.show.overlay {
    display: block;
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-quarter);
    margin: 0.75rem 0;
}

.userInfo span:first-child {
    font-weight: 700;
}

.userInfo span:last-child {
    font-size: var(--text-sm);
}

.tab {
    display: flex;
    align-items: center;
    gap: var(--gap-quarter);
    width: 100%;
    padding: 0.375rem 0;
    cursor: pointer;
    font-weight: 700;
}

.tab:hover .icon {
    color: var(--primary-500);
}

.tab:hover {
    color: var(--primary-600);
    background: #EDE5D5;
}

.icon {
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    color: var(--secondary-darkest);
    width: 2rem;
    margin-left: 1rem;
}

.tab span {
    margin-right: 1rem;
}

.dividerWrapper {
    width: 100%;
}

.divider {
    margin: 0.5rem 0.75rem;
    border: none;
    border-top: 2px solid var(--secondary-darker);
}

.link {
    width: 100%;
}
