.basicHeading {
    margin: 0;
    font-family: var(--font-sans);
    font-weight: 700;
    color: var(--primary-800);
}

.infoHeader {
    display: flex;
    align-items: center;
    gap: var(--gap);
}

.headerIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: var(--radius-round);
    background-color: var(--secondary-dark);
    font-size: var(--text-2xl);
    color: var(--grey-600);
}

.username {
    display: flex;
    flex-direction: column;
    font-size: var(--text-base);
}

.username span:first-child,
.id {
    font-weight: 700;
}

.id {
    font-size: var(--text-base);
}

.deleteBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 7.5rem;
    text-align: center;
    font-weight: 700;
}

.deleteBody p:first-child {
    margin-bottom: 0.25rem;
}

.deleteBody p:last-child {
    margin-top: 0.25rem;
    font-size: var(--text-base);
    color: var(--everest-red);
}

.dropzone {
    display: flex;
    height: 10rem;
    justify-content: center;
    align-items: center;
    border: 2px dashed var(--secondary-darker);
    border-radius: var(--radius);
}
  
.dropzone:hover {
    border-color: var(--primary-500);
    cursor: pointer;
}

.dropzone:hover .prompt {
    color: var(--primary-500);
}

.dropzoneInternal {
    padding: 2rem 1rem;
    width: 100%;
    height: 100%;
}

.dropzone .prompt {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.dropzone .prompt p {
    margin: 0;
    font-weight: 600;
}

.files,
.files ul {
    margin: 0;
}

.files {
    margin-top: 0.5rem;
}

.droppedFiles span {
    padding-bottom: 0.5rem;
    font-weight: 700;
}

.importHeader {
    display: flex;
    flex-direction: column;
    gap: var(--gap-half);
}

.importDescription {
    font-size: var(--text-base);
}

.inlineCsvDownload {
    font-weight: 600;
    color: var(--primary-500);
    font-size: var(--text-base);
}

.csvDownload {
    display: flex;
    align-items: center;
    gap: var(--gap-quarter);
    margin-top: 0.5rem;
    font-weight: 600;
    color: var(--primary-500);
    font-size: var(--text-base);
}

.inlineCsvDownload:hover,
.csvDownload:hover {
    text-decoration: underline;
    color: var(--primary-600);
    cursor: pointer;
}

.csvDownload div {
    display: flex;
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--gap-half);
}

.updateHeader,
.deleteHeader {
    display: flex;
    justify-content: space-between;
}

.message {
    margin-top: 0.5rem;
}

.importTable {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    font-size: var(--text-base);
    overflow-y: auto;
}

.importBlob {
    display: flex;
    flex-direction: column;
}

.importRow {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
}

.importRow:nth-child(2n) {
    background-color: var(--secondary-dark);
    border-radius: var(--radius-sm);
}

.importRow:first-child {
    border-top: 4px solid var(--secondary-dark);
}

.importRow:last-child {
    border-bottom: 4px solid var(--secondary-dark);
}

.importRow > span:first-child {
    font-weight: 700;
    width: 10rem;
}

.importProducts {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--gap-half);
    font-weight: 600;
    font-size: var(--text-sm);
}

.importProducts span {
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius-round);
    background-color: var(--secondary-darker);
}

.radioInputGroup {
    display: flex;
    align-items: center;
    height: 40px;
}

.radioInputGroupLabel {
    display: flex;
    font-size: var(--text-base);
    font-weight: 600;
    width: 100%;
    max-width: 10rem;
}

.radioInputs {
    display: flex;
    gap: var(--gap-double);
    width: 100%;
    margin-top: 2px;
}

.radioInput {
    display: flex;
    cursor: pointer;
}

.radioInputLabel {
    transform: translateY(-2px);
}

@media (min-height: 768px) {
    .form {
        overflow-y: visible;
    }
}
