.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
}


@media (min-width: 1024px) {
    .content {
        justify-content: space-between;
    }
}
