.ag-theme-laneData {
  --ag-foreground-color: var(--grey-700);
  --ag-background-color: var(--secondary);
  --ag-header-foreground-color: var(--grey-800);
  --ag-header-background-color: var(--secondary-dark);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.05);
  --ag-borders: solid 1px;
  --ag-border-radius: 3px;
  --ag-grid-size: 6px;
  --ag-row-height: calc(var(--ag-grid-size) * 7);
  --ag-header-height: calc(var(--ag-grid-size) * 8);
  --ag-list-item-height: calc(var(--ag-grid-size) * 4);
  --ag-font-family: var(--font-sans);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  --ag-card-shadow: none;
  --ag-popup-shadow: none;
}

.ag-theme-laneData .ag-header-cell {
  font-size: 12px;
  --ag-cell-horizontal-padding: 0px;
}

.ag-theme-laneData .ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.ag-theme-laneData .ag-cell {
  --ag-cell-horizontal-padding: 0px;
}

.ag-theme-laneData .ag-cell-value {
  font-size: 12px;
  text-align: center;
}

.ag-theme-laneData .ag-header-group-cell {
  font-size: 18px;
}

.ag-root-wrapper {
  border-radius: var(--radius);
}