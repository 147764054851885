.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.125rem;
    width: 2.125rem;
    border-radius: var(--radius-round);
    background: var(--secondary);
    font-weight: 700;
    font-size: var(--text-xl);
    color: var(--primary-500);
    cursor: pointer;
}
