.card h1 {
    text-align: center;
}

.card p {
    margin: 0;
}

.card a {
    font-weight: 700;
    color: var(--primary-500);
}

.card a:hover {
    color: var(--primary-600);
    text-decoration: underline;
}

.card {
    background-color: var(--secondary);
    border-radius: var(--radius-lg);
    box-shadow: var(--shadow-md);
    width: 100%;
    max-width: 30rem;
}
.card.smartbid {
    height: 40vh;
}

.cardContent {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    height: 100%;
}

.smartbid .cardContent { 
    height: 90%;
}

.narrow {
    max-width: 25.5rem;
}

.wide {
    max-width: 35rem;
}

.fullWidth {
    max-width: 100%;
}

.fullHeight {
    height: 100%;
}

.minPadding .cardContent {
    padding: 0.7rem;
}

.smallPadding .cardContent {
    padding: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--gap-three-quarters);
}

.footer {
    display: flex;
    justify-content: flex-end;
}

.requestFooter {
    display: flex;
    justify-content: space-between;
}

.smartbid {
    height: 30vh;
}

@media (max-width: 480px) {
    .card {
        background-color: transparent;
        border-style: hidden;
        box-shadow: none;
        width: 100%;
        max-width: 30rem;
    }

    .cardContent,
    .minPadding .cardContent,
    .smallPadding .cardContent {
        padding: 0;
    }
}