.main {
    box-sizing: border-box;
    display: flex;
    /* flex-direction: column; */
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    flex: 1;
    min-height: 100vh;
}

.background {
    background-color: var(--primary-500);
    background-image: url('../assets/images/Everest_Background.webp');
    background-size: cover;
    background-position: 0% 80%;
    background-repeat: no-repeat;
}

.container {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    padding-bottom: 2rem;
    width: 100%;
}

.noPadding .container {
    padding: 0;
}

.fullPage {
    padding: 0;
}

.hasSideNav {
    padding-left: 1rem;
}

.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 74rem;
    margin-top: 4rem;
}

.noPadding .section {
    margin-top: 48px;
}

.fullPage .section {
    max-width: 100%;
}

.wide .section {
    max-width: 1920px;
}

.previewOn .section {
    margin-top: 6rem;
}

@media (max-width: 480px) {
    .background {
        background-image: none;
        background-color: var(--secondary);
    }
}

/* @media (min-width: 1366px) {
    .hasSideNav {
        padding-left: 18rem;
    }    
} */