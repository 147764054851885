.filterWrapper {
    display: flex;
    position: relative;
}

.dropdown {
    z-index: 100;
    display: none;
    position: absolute;
    top: 2.25rem;
    min-width: 15rem;
    max-height: calc(100vh - 8rem);
    border-radius: var(--radius-sm);
    background-color: var(--everest-white);
    box-shadow: var(--shadow);
    overflow-y: auto;
}

.overlay {
    z-index: 99;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.open {
    display: block;
}

.searchInputWrapper {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    padding: 1rem;
    padding-bottom: 0.25rem;
    background-color: var(--everest-white);
}

.searchInputContainer {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    padding: 0.375rem 0.5rem;
    border: 2px solid var(--secondary-darker);
    border-radius: var(--radius-sm);
}

.searchInputContainer div {
    display: flex;
    font-size: var(--text-lg);
    color: var(--secondary-darkest);
}

.searchInput {
    border: none;
}

.searchInput::placeholder {
    color: var(--secondary-darker);
}

.subtitle {
    margin-top: 0.25rem;
    font-size: var(--text-base);
    color: var(--secondary-darkest);
}

.selectButtonRow {
    display: flex;
    justify-content: space-around;
    gap: var(--gap-half);
    width: 100%;
    margin-top: 0.5rem;
}

.userList {
    list-style: none;
    margin: 0;
    padding: 0 0 0.5rem 0;
}

.userItem {
    display: flex;
    align-items: center;
    gap: var(--gap-three-quarters);
    padding: 0.5rem 1.125rem;
    color: var(--secondary-darkest) !important;
    font-weight: 600;
    cursor: pointer;
}

.visible {
    color: var(--grey-600);
}

.visible div,
.userItem:hover  {
    color: var(--primary-500);
}

.userItem div {
    display: flex;
    font-size: var(--text-xl);
}

@media (min-width: 768px) {
    .dropdown {
        min-width: 20rem;
    }    
}
